export default function MapPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 18 22"
      width="18px"
      height="18px"
      aria-hidden="true"
      focusable="false"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#002F61" transform="translate(1 1)">
          <path d="M8 20.444s8-4.57 8-12.362C16 4.11 12.418 0 8 0S0 4.11 0 8.082c0 7.223 8 12.362 8 12.362z" />
          <circle cx="8" cy="8" r="3" />
        </g>
        <path d="M-3-1h24v24H-3z" />
      </g>
    </svg>
  );
}
